<template>
  <div class="online-setting fd-w-full fd-h-full fd-bg-white fd-px-4 fd-py-6">
    <div class="fd-w-full fd-flex fd-items-center fd-justify-start fd-mb-4">
      <span class="fd-text-lg fd-font-semibold fd-text-theme-2">{{ $t('onlineSetting.onlineSetting') }}</span>
    </div>

    <div class="fd-px-6 fd-py-4 bg-section">
      <span class="fd-text-lg fd-font-bold fd-text-theme-6">{{ $t('global.languages') }}</span>

      <div class="fd-mt-6">
        <b-tabs fill>
          <b-tab
            v-for="(language, index) in languages"
            :key="index"
          >
            <template #title>
              <div class="fd-flex fd-items-center fd-justify-center fd-pt-2">
                <span class="fd-block">{{ language.name.charAt(0).toUpperCase() + language.name.slice(1) }}</span>
                <span v-if="language.required" class="fd-block fd--mt-2 fd-text-red-500">*</span>
              </div>
            </template>

            <div class="fd-pt-12">
              <b-form-group
                :label="$t('form.title')"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  :placeholder="$t('form.title')"
                  v-model="language.title"
                />
              </b-form-group>

              <b-form-group
                :label="$t('form.description')"
                label-for="description"
                class="fd-mt-8"
              >
                <b-form-textarea
                  id="description"
                  :placeholder="$t('form.description')"
                  rows="3"
                  v-model="language.description"
                />
              </b-form-group>

              <b-form-group
                :label="$t('form.seoMetaData')"
                label-for="seoMetaData"
                class="fd-mt-8"
              >
                <b-form-input
                  id="seoMetaData"
                  :placeholder="$t('form.seoMetaData')"
                  v-model="language.seo_meta_data"
                />
              </b-form-group>

              <b-form-group
                :label="$t('form.secondarySlot')"
                label-for="secondarySlot"
                class="fd-mt-8"
              >
                <b-form-input
                  id="secondarySlot"
                  :placeholder="$t('form.secondarySlot')"
                  v-model="language.secondary_slot"
                />
              </b-form-group>

              <div
                v-if="language.name !== 'turkish'"
                class="fd-flex fd-items-end fd-cursor-pointer fd-mt-8"
                @click="autoTranslate(language)"
              >
                <SvgLoader :name="'translateIcon'"/>
                <span class="fd-block fd-ml-1 fd-text-theme-4 underline">{{ $t('onlineSetting.autoTranslate') }}</span>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <div class="fd-px-6 fd-py-4 bg-section fd-mt-6">
      <span class="fd-text-lg fd-font-bold fd-text-theme-6 fd-mb-3 fd-block">{{ $t('global.description') }}</span>
      <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
        <label for="beginning_from" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
          <span>{{ $t('form.beginningFrom') }}</span>
        </label>
        <div class="fd-w-2/3">
          <b-form-input
            id="beginning_from"
            :placeholder="$t('form.beginningFrom')"
            v-model="description.beginning_from"
          />
        </div>
      </div>
      <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
        <label for="completed_in" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
          <span>{{ $t('form.completedIn') }}</span>
        </label>
        <div class="fd-w-2/3">
          <b-form-input
            id="completed_in"
            :placeholder="$t('form.completedIn')"
            v-model="description.completed_in"
          />
        </div>
      </div>
      <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
        <label for="compilation_percentage" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
          <span>{{ $t('form.compilationPercentage') }}</span>
        </label>
        <div class="fd-w-2/3">
          <b-form-input
            id="compilation_percentage"
            :placeholder="$t('form.compilationPercentage')"
            v-model="description.compilation_percentage"
          />
        </div>
      </div>
      <div class="fd-w-full fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">
        <label for="land_area" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">
          <span>{{ $t('form.landArea') }}</span>
        </label>
        <div class="fd-w-2/3">
          <b-form-input
            id="land_area"
            :placeholder="$t('form.landArea')"
            v-model="description.land_area"
          />
        </div>
      </div>
<!--      <div class="fd-w-full fd-border-b fd-border-theme-5 fd-px-6 fd-py-5 fd-flex fd-items-center fd-justify-between">-->
<!--        <label for="payment" class="fd-block fd-w-1/3 fd-text-sm fd-text-theme-6 fd-font-bold fd-flex fd-items-center">-->
<!--          <span>Payment</span>-->
<!--        </label>-->
<!--        <div class="fd-w-2/3">-->
<!--          <b-form-input-->
<!--            id="payment"-->
<!--            v-model="description.payment"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="fd-px-6 fd-py-4 bg-section fd-mt-6">
      <span class="fd-text-lg fd-font-bold fd-text-theme-6 fd-mb-3 fd-block">{{ $t('features.features') }}</span>
      <b-form-group
        v-slot="{ ariaDescribedby }"
        class="feature--items"
      >
        <div
          v-for="option in features"
          :key="option.id"
          class="fd-py-4 fd-px-4 fd-rounded-lg fd-flex"
          style="background-color: #F4F6F6"
        >
          <b-form-checkbox
            v-model="option.selected"
            :aria-describedby="ariaDescribedby"
            name="findev"
            class="fd-cursor-pointer"
          >
            <div class="fd-flex fd-items-center">
              <img :src="option.icon" :alt="option.name" class="fd-mx-2" style="width: 24px; height: 24px; object-fit: cover; border-radius: 100%;">
              <span class="fd-block fd-ml-2 fd-text-sm fd-font-bold fd-text-theme-6">{{ option.name }}</span>
            </div>
          </b-form-checkbox>
        </div>
      </b-form-group>
    </div>

      <div class="fd-w-full fd-flex fd-items-start fd-justify-end fd-mt-8">
      <b-button
        @click="save"
        variant="primary"
        style="padding-left: 35px !important; padding-right: 35px !important;"
      >
        {{ $t('global.save') }}
      </b-button>
    </div>
    <b-toast id="example-toast" title="BootstrapVue" static></b-toast>
  </div>
</template>

<script>
import { BFormInput, BTabs, BTab, BFormGroup, BFormTextarea, BButton, BToast, BFormCheckbox } from 'bootstrap-vue'
import {mapGetters} from "vuex";

export default {
  name: "Description",
  components: {BFormGroup, BFormInput, BFormTextarea, BTabs, BTab, BButton, BToast, BFormCheckbox},
  data() {
    return {
      validated: false,
      public: false,
      slug: '',
      description: {
        beginning_from: '',
        completed_in: '',
        compilation_percentage: '',
        land_area: '',
        payment: '',
      },
      languages: [
        {
          id: 1,
          name: 'turkish',
          required: true,
          title: '',
          description: '',
          seo_meta_data: '',
          secondary_slot: ''
        },
        {
          id: 2,
          name: 'english',
          required: false,
          title: '',
          description: '',
          seo_meta_data: '',
          secondary_slot: ''
        },
        {
          id: 3,
          name: 'persian',
          required: false,
          title: '',
          description: '',
          seo_meta_data: '',
          secondary_slot: ''
        },
        {
          id: 4,
          name: 'arabic',
          required: false,
          title: '',
          description: '',
          seo_meta_data: '',
          secondary_slot: ''
        },
        {
          id: 5,
          name: 'russian',
          required: false,
          title: '',
          description: '',
          seo_meta_data: '',
          secondary_slot: ''
        }
      ]
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    this.$store.dispatch('project/getLanguages', this.$route.params.id)
      .then(() => {
        this.dataPreparation()
      })
    this.$store.dispatch('project/getFeatures', this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      features: 'project/features'
    })
  },
  methods: {
    async save() {
      await this.checkLanguagesRequired()
      if (!this.validated) {
        this.makeToast()
        return;
      }

      this.$store.dispatch('project/updateOnlineSetting', {
        id: this.$route.params.id,
        data: {
          public: this.public,
          slug: this.slug,
          language: this.languages
        }
      })
    },
    async autoTranslate(language) {
      await this.checkLanguagesRequired()
      if (!this.validated) {
        this.makeToast()
        return;
      }
      const turkish = this.languages.find(item => item.name === 'turkish')
      this.$store.dispatch('project/updateOnlineSettingAutoTranslate', {
        id: this.$route.params.id,
        languageId: language.id,
        data: {
          name: language.name,
          title: turkish.title,
          description: turkish.description,
          seo_meta_data : turkish.seo_meta_data,
          language_id: turkish.id
        }
      })
        .then(response => {
          console.log(response, 'RESPo')
        })
    },
    checkLanguagesRequired() {
      return this.languages.forEach(item => {
        if (!item.required) return

        if (item.title || item.description || item.seo_meta_data) {
          this.validated = true
        } else {
          this.validated = false
        }
      })
    },
    dataPreparation() {
      const languages = this.$store.getters["project/languages"]

      this.languages.forEach(item => {

        const lang = languages.find(lang => {
          return lang.name.toLowerCase() === item.name.toLowerCase()
        })
        if (!lang) return

        item.title = lang.title
        item.description = lang.description
        item.seo_meta_data = lang.seo_meta_data
      })
    },
    makeToast() {
      this.$bvToast.toast('Turkish language is required', {
        title: 'Error',
        toaster: 'b-toaster-top-right',
        variant: 'danger',
        solid: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-section {
  background: rgba(0, 0, 0, 0.0001);
  box-shadow: 0 4px 24px rgba(40, 74, 51, 0.06);
  border-radius: 6px;
}
::v-deep .feature--items {
  & > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
}
</style>